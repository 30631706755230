<template>
  <div>
    <div style="padding: 0 20px 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">现有产品</div>
      </div>
      <el-button type="primary" @click="add(table, info)" style="margin-bottom: 20px" :disabled="disabled">新增</el-button>
      <el-table :data="table" style="width: 100%">
        <el-table-column label="产品名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.productName" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="型号">
          <template slot-scope="scope">
            <el-input v-model="scope.row.model" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="年产量（万件/万吨/万台）" width="220">
          <template slot-scope="scope">
            <el-input type="number" v-model.number="scope.row.annualOutPut" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="年产值（万元）">
          <template slot-scope="scope">
            <el-input type="number" v-model.number="scope.row.annualValue" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="利润（万元）">
          <template slot-scope="scope">
            <el-input type="number" v-model.number="scope.row.profit" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remarks" placeholder="请输入" :maxlength="500" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button type="danger" plain @click="deletes(scope.$index, table,scope.row)" v-if="scope.$index != 0" :disabled="disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">产品生产工艺流程图</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="productFlowChart"
          :limit="99"  multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip=" 文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">三废处理工艺流程图</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="threeWastesFlowChart"
          :limit="99"  multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip=" 文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from '@/components/FileUpload';
import { mapState } from 'vuex';
export default {
  name: 'basicInfo',
  data() {
    return {
      table: [
        {
          productName: '',
          model: '',
          annualOutPut: '',
          annualValue: '',
          profit: '',
          remarks: '',
        },
      ],
      info: {
        productName: '',
        model: '',
        annualOutPut: '',
        annualValue: '',
        profit: '',
        remarks: '',
      },
      productFlowChart: [],
      threeWastesFlowChart: [],
    };
  },
  components: {
    fileUpload,
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
      // disabled: (state) => false
    }),
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getProjectExistingProducts(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result))
            this.table = data.productsList.length > 0 ? data.productsList : this.table;
            this.productFlowChart = data.productFlowChart
            this.threeWastesFlowChart = data.threeWastesFlowChart
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({},obj));
    },
    async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row})
      if(row.id){
         await this.$confirm("确认删除?","删除",{ type:'warning' })
         await this.$api.deleteProjectExistingProducts(row.id);
         this.$message.success("删除成功")
      }
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      this.table.forEach((e) => {
        console.log("产品信息表单验证",e);
       
        for (const key in this.info) {
          if(["model","remarks"].includes(key)){
            continue; //如果是型号 和备注 则跳过本次循环
          }
          if (!e[key] && e[key] !== 0&& e[key] !== '0') {
            this.$message.error({ message: `请将现有产品填写完整`, offset: 80 });
            flage = true;
            return;
          }
        }
      });
      if (flage) return false;
      if (this.productFlowChart.length < 1) {
        this.$message.error({ message: `请上传 产品生产工艺流程图`, offset: 80 });
        return false;
      }
      if (this.threeWastesFlowChart.length < 1) {
        this.$message.error({ message: `请上传 三废处理工艺流程图`, offset: 80 });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
