<template>
  <div>
    <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="85px" label-position="top" style="padding: 0 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">基础信息</div>
      </div>
      <el-row :gutter="64">
        <el-col :span="12">
          <el-form-item label="企业名称" prop="nameOfEnterprise">
            <el-input v-model="saveData.nameOfEnterprise" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属行业" prop="industry">
            <el-input v-model="saveData.industry" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册资金（万元）" prop="registeredCapital">
            <el-input type="number" v-model.number="saveData.registeredCapital" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法定代表人" prop="legalPerson">
            <el-input v-model="saveData.legalPerson" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目对接联系人" prop="projectContact">
            <el-input v-model="saveData.projectContact" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对接人联系方式" prop="contactWay"  >
            <el-input v-model="saveData.contactWay"  type="number" @input="value=>saveData.contactWay.length > 11 ? saveData.contactWay=saveData.contactWay.slice(0,11) : value" placeholder="请输入11位手机号码" maxlength="11"   
  show-word-limit :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建筑面积（亩）" prop="builtUpArea">
            <el-input type="number" v-model.number="saveData.builtUpArea" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="占地面积:">
            <!-- <el-input type="number" v-model.number="saveData.areaCovered" placeholder="请输入" :maxlength="20"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="自有（亩）" prop="have">
            <el-input type="number" v-model.number="saveData.have" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="租赁（亩）" prop="lease">
            <el-input type="number" v-model.number="saveData.lease" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合计(亩)">
            <el-input type="number" v-model.number="total1" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现有员工人数:">
            <!-- <el-input type="number" v-model="saveData.existEmployeesNum" placeholder="请输入" :maxlength="20"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="管理人员人数(人)" prop="managersNum">
            <el-input-plus type="integer" v-model="saveData.managersNum" placeholder="请输入管理人员人数"  :disabled="disabled"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="研发技术人员人数(人)" prop="artisanNum">
            <el-input-plus type="integer" v-model="saveData.artisanNum" placeholder="请输入研发技术人员人数"  :disabled="disabled"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="生产人员人数(人)" prop="productionPersonnelNum">
            <el-input-plus type="integer" v-model="saveData.productionPersonnelNum" placeholder="请输入生产人员人数"  :disabled="disabled"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="合计(人)">
            <el-input type="number" v-model.number="total2" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="单位地址" prop="unitAddress">
            <el-input v-model="saveData.unitAddress" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="主要产品" prop="mainProducts">
            <el-input v-model="saveData.mainProducts" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="外销比例（%）" prop="exportProportion">
            <el-input type="number" v-model.number="saveData.exportProportion" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="外销地区" prop="exportRegions">
            <el-input v-model="saveData.exportRegions" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="年利润额（万元）" prop="annualProfit">
            <el-input type="number" v-model.number="saveData.annualProfit" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位能耗增加值（万元/吨标煤）" prop="valueAdded">
            <el-input type="number" v-model.number="saveData.valueAdded" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位排放增加值（万元/吨）" prop="dischargeUnitEmission">
            <el-input type="number" v-model.number="saveData.dischargeUnitEmission" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="研发经费支持占营业收入比例（%）" prop="devBusRatio">
            <el-input type="number" v-model.number="saveData.devBusRatio" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="专利个数(个)" prop="patentsNum">
            <el-input-plus type="integer" v-model="saveData.patentsNum" placeholder="请输入专利个数"  :disabled="disabled"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发明专利个数(个)" prop="inventionPatentsNum">
            <el-input-plus type="integer" v-model="saveData.inventionPatentsNum" placeholder="请输入发明专利个数"  :disabled="disabled"></el-input-plus>
         </el-form-item>
        </el-col>
        <el-col :span="12" class="inputList">
          <el-form-item label="销售收入（万元）" prop="totalTaxAndSalesRevenue" :rules="{ required: true, validator: validatoIncome, trigger: 'blur' }">
            <div v-for="(item, index) in saveData.totalTaxAndSalesRevenue" :key="index">
              <el-input type="number" v-model.number="item.totalTax" placeholder="请输入" :maxlength="20" class="input1" :disabled="disabled">
                <template slot="prepend">{{ item.year }}年</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputList">
          <el-form-item label="税收总额（万元）" prop="totalTaxAndSalesRevenue" :rules="{ required: true, validator: validatoTotalRevenue, trigger: 'blur' }">
            <div v-for="(item, index) in saveData.totalTaxAndSalesRevenue" :key="index">
              <el-input type="number" v-model.number="item.salesRevenue" placeholder="请输入" :maxlength="20" class="input1" :disabled="disabled">
                <template slot="prepend">{{ item.year }}年</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="企业优势特点" prop="enAdvantage">
            <el-input type="textarea" :rows="8" v-model="saveData.enAdvantage" placeholder="请输入企业优势特点" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox required">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业营业执照复印件</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="license"
          :limit="99"
          multiple
          :disabled="disabled"
          :size-limit="20"
          size-unit="M"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox required">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业近三年财务报表</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="financial"
          :limit="99"
          multiple
          :disabled="disabled"
          :size-limit="20"
          size-unit="M"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox required">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业近三年财务完税证明</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="financialStatistical"
          :limit="99"
          multiple
          :disabled="disabled"
          :size-limit="20"
          size-unit="M"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业简介</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="companyProfile"
          :limit="99"
          multiple
          :disabled="disabled"
          :size-limit="20"
          size-unit="M"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from '@/components/FileUpload';
import { ProjectSmallStep } from '@/common/enum';
import ElInputPlus from "@/components/el-input-plus.vue"
import dayjs from 'dayjs';
const generateYearsList =  ()=>Array.from({length:3}).map((_,index)=>({year:dayjs().add((index+1 )* -1,'y').format("YYYY")}))
console.log(`生成列表`,generateYearsList())
/** 可编辑的审核步骤 */
const editableSteps = [
 ProjectSmallStep.WAIT_EXAMINE,
 ProjectSmallStep.EXAMINE_REJECT,
 ProjectSmallStep.WAIT_SECOND_EXAMINE,
]
const  checkPhone = (rule,value,callback)=>{
			var reg=/^1[3456789]\d{9}$/;
    console.log("手机号码验证",value,reg.test(value))
			if(reg.test(value) && value.length === 11){
        console.log("验证通过",value,reg.test(value))
				callback();
			}else{
        console.error("手机号验证错误",value,reg.test(value))
				callback(new Error("请输入正确的手机号码格式"));
			}
		}
export default {
  name: 'basicInfo',
  watch:{
    saveData:{
      deep:true,
      handler(nv){
        localStorage.setItem("EDITING_FLAG",JSON.stringify(nv))
      }
    }
  },
  data() {
    return {
      saveData: {
        industry: '',
        nameOfEnterprise: '',
        registeredCapital: '',
        legalPerson: '',
        projectContact: '',
        contactWay: '',
        builtUpArea: '',
        // areaCovered: '',
        have: '',
        lease: '',
        // total: '',
        // existEmployeesNum: '',
        managersNum: '',
        artisanNum: '',
        productionPersonnelNum: '',
        unitAddress: '',
        mainProducts: '',
        exportProportion: '',
        exportRegions: '',
        annualProfit: '',
        valueAdded: '',
        dischargeUnitEmission: '',
        devBusRatio: '',
        patentsNum: '',
        inventionPatentsNum: '',
        //从当前年起倒数3年
        totalTaxAndSalesRevenue:generateYearsList(),
        enAdvantage: '',
      },
      saveRules: {
        nameOfEnterprise: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        industry: [{ required: true, message: '请输入所属行业', trigger: 'blur' }],
        registeredCapital: [{ required: true, message: '请输入注册资金', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入法定代表人', trigger: 'blur' }],
        projectContact: [{ required: true, message: '请输入项目联系人', trigger: 'blur' }],
        contactWay: [{ required: true, message: '',validator:checkPhone, trigger: 'blur' }],
        builtUpArea: [{ required: true, message: '请输入建筑面积', trigger: 'blur' }],
        // areaCovered: [{ required: true, message: '请输入占地面积', trigger: 'blur' }],
        have: [{ required: true, message: '请输入自有', trigger: 'blur' }],
        lease: [{ required: true, message: '请输入租赁', trigger: 'blur' }],
        // total: [{ required: true, message: '请输入合计', trigger: 'blur' }],
        // existEmployeesNum: [{ required: true, message: '请输入现有员工人数 ', trigger: 'blur' }],
        managersNum: [{ required: true, message: '请输入管理人员人数', trigger: 'blur' }],
        artisanNum: [{ required: true, message: '请输入研发技术人员人数', trigger: 'blur' }],
        productionPersonnelNum: [{ required: true, message: '请输入生产人员人数', trigger: 'blur' }],
        unitAddress: [{ required: true, message: '请输入单位地址', trigger: 'blur' }],
        mainProducts: [{ required: true, message: '请输入主要产品', trigger: 'blur' }],
        // exportProportion: [{ required: true, message: '请输入外销比例', trigger: 'blur' }],
        // exportRegions: [{ required: true, message: '请输入外销地区', trigger: 'blur' }],
        annualProfit: [{ required: true, message: '请输入年利润额', trigger: 'blur' }],
        // valueAdded: [{ required: true, message: '请输入单位能耗增加值', trigger: 'blur' }],
        // dischargeUnitEmission: [{ required: true, message: '请输入单位排放增加值', trigger: 'blur' }],
        // devBusRatio: [{ required: true, message: '请输入研发经费支持占营业收入比例', trigger: 'blur' }],
        patentsNum: [{ required: true, message: '请输入专利个数(个)', trigger: 'blur' }],
        inventionPatentsNum: [{ required: true, message: '请输入发明专利个数(个)', trigger: 'blur' }],
        // enAdvantage: [{ required: true, message: '请输入企业优势特点', trigger: 'blur' }],
      },
      license: [],
      financial: [],
      financialStatistical: [],
      companyProfile: [],
    };
  },
  components: {
    fileUpload,
    ElInputPlus
  },
  computed: {
    disabled() {
        /** @type { ProjectSmallStep } 当前步骤*/
      const currentStep = this.saveData.projectSmallStep
      const disabled = !editableSteps.includes(currentStep) && !!this.saveData.id
      console.log("diabled 判断",{currentStep,disabled,editableSteps})
      this.$store.commit('updateValue', { formDisabled: disabled });
      return disabled;
    },
    total1() {
      return (Number(this.saveData.have) + Number(this.saveData.lease) || 0).toFixed(0);
    },
    total2() {
      return ( Number(this.saveData.managersNum) + Number(this.saveData.artisanNum) + Number(this.saveData.productionPersonnelNum) || 0).toFixed(0);
    },
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getProjectBasic(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            this.saveData = data.projectBasic || {};
            this.license = data.license;
            this.financial = data.financial;
            this.financialStatistical = data.financialStatistical;
            this.companyProfile = data.companyProfile;
            this.$set(
              this.saveData,
              'totalTaxAndSalesRevenue',
              data.totalTaxAndSalesRevenue.length > 0 ? data.totalTaxAndSalesRevenue : generateYearsList()
            );
          }
        });
      }
    },
    /**
     * 数据验证
     * @param {boolean} skipField 跳过字段验证
     * @param {boolean} skipFile 跳过文件验证
     */
    async validatoData({ skipField=false,skipFile = FinalizationRegistry }={}) {
      this.$refs['saveForm'].validate((state,obj)=>{
           if(!state) this.$message.error(obj[ Object.keys(obj)[0]][0].message)
        })
      let valia = await  this.$refs['saveForm'].validate().catch((e,f,g)=>{
        //  console.log('表单验证未通过',{e,f,g})
      });
      // if(!valia){
      //    this.$refs['saveForm'].validate((state,obj)=>{
      //      console.log('表单验证未通过',{state,obj})
      //   })
      // }
      console.log("表单验证结果",valia)
      let data  = skipField ? skipField :valia ;
      if (!data)return false;
      if(skipFile){
        return true;
      }
      if (this.license.length < 1) {
        this.$message.error({ message: `请上传 企业营业执照复印件`, offset: 80 });
        return false;
      }  
      if (this.financial.length < 1) {
        this.$message.error({ message: `请上传 企业近三年财务报表`, offset: 80 });
        return false;
      }
      if (this.financialStatistical.length < 1) {
        this.$message.error({ message: `请上传 企业近三年财务完税证明`, offset: 80 });
        return false;
      }

      return true;
    },
    validatoIncome(rule, value, callback) {
      // value.forEach((v) => {
      //   if (v.totalTax === '' || v.totalTax === undefined) {
      //     callback(new Error(`请输入${v.year}销售收入`));
      //     return;
      //   }
      // });
      callback();
    },
    validatoTotalRevenue(rule, value, callback) {
      // value.forEach((v) => {
      //   if (v.salesRevenue === '' || v.salesRevenue === undefined) {
      //     callback(new Error(`请输入${v.year}年税收总额`));
      //     return;
      //   }
      // });
      callback();
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/ .el-input-group__prepend {
  line-height: 38px;
  text-align: center;
  width: 85px;
  background-color: #f5f5f5;
}
.inputList {
  /deep/.el-input-group--prepend {
    display: flex !important;
    .el-input__inner:focus {
      border-color: #409eff !important;
    }
  }
  .input1 {
    /deep/.el-input-group__prepend {
      border-radius: 4px 0 0 0;
    }
    /deep/.el-input__inner {
      border-left: 1px solid transparent;
    }
  }
  .input2 {
    /deep/.el-input-group__prepend {
      border: unset;
      border-left: 1px solid #dddddd;
      border-radius: 0;
    }
    /deep/.el-input__inner {
      border: 1px solid transparent;
      border-right: 1px solid #dddddd;
    }
  }
  /deep/.el-form-item.is-error {
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
  .input3 {
    /deep/.el-input-group__prepend {
      border-radius: 0 0 0 4px;
    }
    /deep/.el-input__inner {
      border-left: 1px solid transparent;
    }
  }
}
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
  &.required{
    .title{
      .text{
        &::before{
          content: "*";
          color:red;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
