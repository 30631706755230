<template>
  <div>
    <div style="padding: 0 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">人员安排</div>
      </div>
      <el-button type="primary" @click="add(table, info)" style=" margin-bottom: 20px" :disabled="disabled">新增</el-button>
      <el-table :data="table" style="width: 100%">
        <el-table-column label="数量(个)">
          <template slot-scope="scope">
            <el-input-plus type="integer" :disabled="disabled" v-model="scope.row.num" placeholder="请输入" :maxlength="20" ></el-input-plus>
            <!-- <el-input :disabled="disabled" v-model="scope.row.num" placeholder="请输入" :maxlength="20"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="解决办法">
          <template slot-scope="scope">
            <el-input :disabled="disabled" v-model="scope.row.solution" placeholder="请输入" :maxlength="20"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="平均工资水平(元)">
          <template slot-scope="scope">
            <el-input :disabled="disabled"  v-model="scope.row.wage" placeholder="请输入" :maxlength="20"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <el-select :disabled="disabled" v-model="scope.row.type" placeholder="请选择">
              <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button type="danger" plain @click="deletes(scope.$index, table,scope.row)" v-if="scope.$index != 0" :disabled="disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>

    <el-form ref="saveForm" :model="saveData"  @submit.native.prevent label-width="85px" label-position="top" style="padding: 0 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">能源要求</div>
      </div>
      <el-row :gutter="64">
        <el-col :span="12">
          <el-form-item label="电力装机容量(KA)" prop="installedPowerCapacity">
            <el-input type="number" :disabled="disabled" v-model.number="saveData.installedPowerCapacity" placeholder="请输入电力装机容量" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电力年用电量（kwh）" prop="annualElectricityConsumption">
            <el-input type="number" :disabled="disabled" v-model.number="saveData.annualElectricityConsumption" placeholder="请输入电力年用电量" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="煤月用量（吨）" prop="monthlyCoalConsumption">
            <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyCoalConsumption" placeholder="请输入煤月用量" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="水月用量（吨）" prop="monthlyWaterConsumption">
            <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyWaterConsumption" placeholder="请输入煤月用量" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="油用月用量（吨）" prop="monthlyOilConsumption">
            <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyOilConsumption" placeholder="请输入油用月用量" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="其他" prop="other">
            <el-input :disabled="disabled" v-model="saveData.other" placeholder="请输入其他" :maxlength="100"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="综合能耗(t/a)" prop="comprehensiveEnergyConsumption">
            <el-input :disabled="disabled" v-model="saveData.comprehensiveEnergyConsumption" placeholder="请输入综合能耗" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="万元产值能耗(kg)" prop="energyPer10000Output">
            <el-input :disabled="disabled" v-model="saveData.energyPer10000Output" placeholder="请输入万元产值能耗" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业建设期（月）" prop="enterpriseConstructDate">
            
            <el-input-plus type="integer" :disabled="disabled" v-model="saveData.enterpriseConstructDate" placeholder="请输入企业建设期（月）" :maxlength="20"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="调试期(月)" prop="commissioningPeriodDate">
            <el-input-plus type="integer" :disabled="disabled" v-model="saveData.commissioningPeriodDate" placeholder="请输入调试期月数" :maxlength="20"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预计投产日期" prop="expectedProductionDate">
            <el-date-picker :disabled="disabled" v-model="saveData.expectedProductionDate" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'basicInfo',
  data() {
    return {
      saveData: {
        installedPowerCapacity: '',
        annualElectricityConsumption: '',
        monthlyCoalConsumption: '',
        monthlyWaterConsumption: '',
        monthlyOilConsumption: '',
        other: '',
        comprehensiveEnergyConsumption: '',
        energyPer10000Output: '',
        enterpriseConstructDate: '',
        commissioningPeriodDate: '',
        expectedProductionDate: '',
      },
      table: [
        {
          num: '',
          solution: '',
          wage: '',
          type: '',
        },
      ],
      type: [
        { value: '1', label: '管理人员' },
        { value: '2', label: '技术人员' },
        { value: '3', label: '生产工人' },
        { value: '4', label: '职工人数' },
      ],
      info: {
        num: '',
        solution: '',
        wage: '',
        type: '',
      },
      saveRules: {
        installedPowerCapacity: [{ required: true, message: '请输入电力装机容量', trigger: 'blur' }],
        annualElectricityConsumption: [{ required: true, message: '请输入电力年用电量', trigger: 'blur' }],
        monthlyWaterConsumption: [{ required: true, message: '请输入水月用量', trigger: 'blur' }],
        monthlyCoalConsumption: [{ required: true, message: '请输入煤月用量', trigger: 'blur' }],
        monthlyOilConsumption: [{ required: true, message: '请输入油用月用量', trigger: 'blur' }],
        other: [{ required: true, message: '请输入其他', trigger: 'blur' }],
        comprehensiveEnergyConsumption: [{ required: true, message: '请输入综合能耗', trigger: 'blur' }],
        energyPer10000Output: [{ required: true, message: '请输入万元产值能耗', trigger: 'blur' }],
        enterpriseConstructDate: [{ required: true, message: '请输入企业建设期', trigger: 'blur' }],
        commissioningPeriodDate: [{ required: true, message: '请输入调试期', trigger: 'blur' }],
        expectedProductionDate: [{ required: true, message: '请选择预计投产日期', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getProjectPersonArrange(this.$store.state.projectId).then((res) => {
          if (res.success) {
            // let data = JSON.parse(JSON.stringify(res.result));
            this.saveData = res.result.energyRequireEntity || {};
            this.table = res.result.personArrangeList.length > 0 ? res.result.personArrangeList : this.table;
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
    async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row})
      if(row.id){
         await this.$confirm("确认删除?","删除",{ type:'warning' })
         await this.$api.deleteProjectPersonArrange(row.id);
         this.$message.success("删除成功")
      } 
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      this.table.forEach((e) => {
        for (const key in this.info) {
          if(["solution"].includes(key))continue;
          if (!e[key] && e[key] !== 0&& e[key] !== '0') {
            this.$message.error({ message: `请将人员安排填写完整`, offset: 80 });
            flage = true;
            return;
          }
        }
      });
      if (flage) return false;
      this.$refs['saveForm'].validate((valid) => {
        flage = valid;
      });
      if (!flage) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
