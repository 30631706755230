<template>
  <div class="box wrapper">

    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterprisesInPark' }">园区入驻</el-breadcrumb-item>
      <el-breadcrumb-item>入园审核新增</el-breadcrumb-item>
    </el-breadcrumb>
   
    <div class="content table-box table-no-border">
      <el-tabs v-model="activeName" type="card" :before-leave="handleBeforeTabLeave" @tab-click="handleClick" :stretch="true">
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in list" :key="index" >
          <span slot="label" :class="{
            disabled:!projectId && index,
          }"><i style="color:red;"  v-if="item.required">*</i>{{item.label}}</span>
          <component :is="item.name" :ref="item.name"></component>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-button type="primary" @click="save()" :loading="loading" v-if="!disabled" style="margin: 20px 12px 30px 24px">保存</el-button>
    <el-button @click="$router.go(-1)" style="margin: 20px 12px 30px 24px">{{ disabled ? '返回' : '取消' }}</el-button>
  </div>
</template>
<script>
import basicInfo from '../components/basicInfo';
import product from '../components/product';
import equipmentList from '../components/equipmentList';
import situation from '../components/situation';
import plan from '../components/plan';
import betriebsProdukte from '../components/betriebsProdukte';
import economicAnalysis from '../components/economicAnalysis';
import other from '../components/other';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      
      activeName: 'basicInfo',
      list: [
        { label: '企业基础信息', name: 'basicInfo',required:true },
        { label: '拟投资项目情况', name: 'situation',required:true },
        { label: '现有产品', name: 'product' },
        { label: '现有设备清单', name: 'equipmentList' },
        { label: '固定资产投资计划', name: 'plan' },
        { label: '拟生产经营产品', name: 'betriebsProdukte' },
        { label: '经济效益分析', name: 'economicAnalysis' },
        { label: '其他', name: 'other' },
      ],
      id: null,
    };
  },
  created() {
    this.getId();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.out, true);
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if (!this.disabled) {
      //新增时
      setTimeout(() => {
        // hash模式下，此处必须要加延迟执行，主要解决浏览器前进后退带来的闪现
        this.$confirm('系统可能不会保存您所做的更改。', '离开当前页面?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then((result) => {
            next();
          })
          .catch(() => {
            next(false);
          });
      }, 200);
    } else {
      next();
    }
  },
  mounted() {
    window.page= this;
    const form = this.$route.query.form
    if(this.list.map(item=>item.name).includes(form)){
      this.activeName = form
    }


    this.$refs[this.activeName][0].init();
    //当浏览器窗口关闭或者刷新时，会触发beforeunload事件。当前页面不会直接关闭，可以点击确定按钮关闭或刷新，也可以取消关闭或刷新。
    window.addEventListener('beforeunload', this.out, true);
    setTimeout(()=>{
       localStorage.removeItem("EDITING_FLAG")
    },1000)
   
  },
  watch:{
    activeName(nv){
        const path = this.$route.path
        this.$router.replace({path,query:{form:nv}})
    },
    projectId(nv,ov){
       this.$store.commit('updateValue', { projectId:nv });
    },
    curForm:{
      handler(nv){
          console.log("表单值更新",nv)
      },
      deep:true
    }
  
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
    projectId () {
      const id = this.$route.params.id || this.id || null;
      this.$store.commit('updateValue', { projectId: id });
      return id
    }
  },
  methods: {
    async handleBeforeTabLeave(name){
      console.log(name,this.projectId,this.id);

      if(!this.projectId && !this.id){
        this.$message.warning("请先提交基础信息填写")
        throw("请先提交基础信息填写")    
      }else if(localStorage.getItem("EDITING_FLAG")){
          const confirm =  await this.$confirm('系统可能不会保存您所做的更改。', '离开当前表单?', { 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        });
        if(confirm){
          localStorage.removeItem("EDITING_FLAG")
          return true
        }
      }
      this.$refs[name][0].init();
      return true
      
      
    },
    getId(){
      this.id = this.projectId;
      return this.projectId
    },
    out(e) {
      if (!this.disabled) {
        //新增时
        if (/Apple/.test(navigator.vendor)) {
          e.preventDefault();
        } else {
          e.returnValue = 'a';
        }
      } else {
        e.preventDefault();
      }
    },
    handleClick(tab, event) {
      console.log("点击事件",tab)
    },
    async save() {
      this.loading = true;
     
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if (this.activeName == 'basicInfo') {
        if (!await this.$refs.basicInfo[0].validatoData({
          skipField:false,
          skipFile:false,
        })) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.basicInfo[0].saveData));
         console.log("保存",params)
        params.license = JSON.stringify(this.$refs.basicInfo[0].license);
        params.financial = JSON.stringify(this.$refs.basicInfo[0].financial);
        params.companyProfile = JSON.stringify(this.$refs.basicInfo[0].companyProfile);
        params.financialStatistical = JSON.stringify(this.$refs.basicInfo[0].financialStatistical);
        params.totalTaxAndSalesRevenue = JSON.stringify(params.totalTaxAndSalesRevenue);
        params.projectId = this.id;
        this.$api.editProjectBasic(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          console.log("保存成功",res,this.id,res.result)
          // this.router.params.id = res.result;
          if(!this.id){//新增情况
             window.location.replace(`${window.location.href}/${res.result}`)
          }
          this.id = res.result
          // this.getId();
        });
      } else if (!this.id) {
        this.$message.error({ message: `请先保存企业基础信息`, offset: 80 });
        return;
      }
      if (this.activeName == 'product') {
        if (!await this.$refs.product[0].validatoData()) {
          return;
        }
        let params = {};
        params.existingProductList = JSON.stringify(this.$refs.product[0].table);
        params.productFlowChart = JSON.stringify(this.$refs.product[0].productFlowChart);
        params.threeWastesFlowChart = JSON.stringify(this.$refs.product[0].threeWastesFlowChart);
        params.projectId = this.id;
        this.$api.editProjectExistingProducts(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'equipmentList') {
        if (!await this.$refs.equipmentList[0].validatoData()) {
          return;
        }
        let params = {};
        params.existingEquipmentList = JSON.stringify(this.$refs.equipmentList[0].table);
        params.projectId = this.id;
        this.$api.editProjectEquipment(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'situation') {
        if (!await this.$refs.situation[0].validatoData()) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.situation[0].saveData));
        params.projectId = this.id;
        this.$api.editProjectWillInvested(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'plan') {
        if (!await this.$refs.plan[0].validatoData()) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.plan[0].saveData));
        params.equipmentInvestmentList = JSON.stringify(this.$refs.plan[0].equipmentInvestmentList);
        let infrastructureInvestmentList = JSON.parse(JSON.stringify(this.$refs.plan[0].infrastructureInvestmentList));
        infrastructureInvestmentList.pop();
        params.infrastructureInvestmentList = JSON.stringify(infrastructureInvestmentList);
        params.liquidityList = JSON.stringify(this.$refs.plan[0].liquidityList);
        params.projectId = this.id;
        this.$api.editProjectFixedInvestment(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'betriebsProdukte') {
        if (!await this.$refs.betriebsProdukte[0].validatoData()) {
          return;
        }
        let params = {};
        params.entity = JSON.stringify(this.$refs.betriebsProdukte[0].table);
        params.productionList = JSON.stringify(this.$refs.betriebsProdukte[0].productionList);
        params.treatmentList = JSON.stringify(this.$refs.betriebsProdukte[0].treatmentList);
        params.projectId = this.id;
        this.$api.editProjectWillProducts(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'economicAnalysis') {
        if (!await this.$refs.economicAnalysis[0].validatoData()) {
          return;
        }
        let params = {};
        params.entity = JSON.stringify(this.$refs.economicAnalysis[0].table);
        params.projectId = this.id;
        this.$api.editProjectBenefit(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'other') {
        if (!await  this.$refs.other[0].validatoData()) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.other[0].saveData));
        params.personArrangeListStr = JSON.stringify(this.$refs.other[0].table);
        params.projectId = this.id;
        this.$api.editProjectPersonArrange(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
    },
  },
  components: {
    basicInfo,
    product,
    equipmentList,
    situation,
    plan,
    betriebsProdukte,
    economicAnalysis,
    other,
  },
};
</script>
<style lang='scss' scoped>
.disabled{
  color: rgb(170, 169, 169);
}
.box {
  .content {
    // padding: 20px;
    background-color: #fff;
    overflow: hidden;
    /deep/ .el-tabs__item.is-active {
      color: #135694;
    }
    /deep/ .el-tabs__item:hover {
      color: #135694;
    }
    /deep/.el-tabs__header {
      margin: 20px;
      margin-bottom: 15px;
    }
    /deep/ .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin: 20px 0 19px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    /deep/ .el-form--label-top .el-form-item__label {
      line-height: 18px;
    }
    /deep/ .el-table__body {
      td {
        border: none;
      }
    }
  }
}
</style>
