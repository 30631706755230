<template>
  <div>
    <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="85px" label-position="top">
      <div style="padding: 0 20px 10px">
        <div class="title">
          <div class="line"></div>
          <div class="text">固定资产投资</div>
        </div>
        <el-row :gutter="64">
          <el-col :span="12">
            <el-form-item label="土地购置（亩）" prop="landPurchase">
              <el-input type="number" v-model.number="saveData.landPurchase" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计费用（万元）" prop="estimatedCost">
              <el-input type="number" v-model.number="saveData.estimatedCost" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>

      <div style="padding: 0 20px 10px">
        <div class="title">
          <div class="line"></div>
          <div class="text">基建投入</div>
        </div>
        <!-- <el-button type="primary" @click="add(infrastructureInvestmentList, info1)" style="margin-bottom: 20px">新增</el-button> -->
        <el-table :data="infrastructureInvestmentList" style="width: 100%">
          <el-table-column label="建设内容" prop="constructionContent" width="100"></el-table-column>
          <el-table-column label="建设面积（m²）">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.constructionArea" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="建设楼层">
            <template slot-scope="scope">
              <el-input-plus type="integer" v-model="scope.row.constructionFloors" placeholder="请输入" :maxlength="20" :disabled="disabled" ></el-input-plus>
              <!-- <el-input type="number" v-model="scope.row.constructionFloors" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input> -->
            </template>
          </el-table-column>
          <el-table-column label="占地面积（m²）">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.areaCovered" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column label="研发面积（m²）">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.researchArea" placeholder="请输入" :maxlength="20"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="其他面积（m²）">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.otherArea" placeholder="请输入" :maxlength="20"></el-input>
            </template>
          </el-table-column> -->
          <el-table-column label="预计费用（万元）">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.estimatedCost" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column width="100">
            <template slot-scope="scope">
              <el-button type="danger" plain @click="deletes(scope.$index, infrastructureInvestmentList)" v-if="scope.$index != 0">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- <el-form-item class="prepend-input">
          <el-input v-model.number="expectTotal" placeholder="请输入" type="number" :maxlength="20">
            <template slot="prepend">预计费用合计</template>
          </el-input>
        </el-form-item> -->
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="padding: 0 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">设备投资</div>
        </div>
        <el-button type="primary" @click="add(equipmentInvestmentList, info2)" style="margin-bottom: 20px" :disabled="disabled"
          >新增</el-button
        >
        <el-table :data="equipmentInvestmentList" style="width: 100%">
          <el-table-column label="生产设备(万元)">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.productionEquipment" type="number" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="环保设备(万元)">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.greenFacilities" type="number" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-button type="danger" plain @click="deletes(scope.$index, equipmentInvestmentList,scope.row,'equipmentInvestmentList')" v-if="scope.$index != 0" :disabled="disabled"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-form-item class="prepend-input">
          <el-input v-model.number="equipmentTotal" type="number" disabled :maxlength="20">
            <template slot="prepend">设备合计(万元)</template>
          </el-input>
        </el-form-item>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="padding: 0 20px">
        <el-form-item class="prepend-input">
          <el-input v-model.number="FixedAssetsTotal" type="number" disabled :maxlength="20">
            <template slot="prepend">固定资产投资合计(万元)</template>
          </el-input>
        </el-form-item>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="padding: 0 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">流动资金安排</div>
        </div>
        <el-button type="primary" @click="add(liquidityList, info3)" style="margin-bottom: 20px" :disabled="disabled"
          >新增</el-button
        >
        <el-table :data="liquidityList" style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="来源">
            <template slot-scope="scope">
              <el-input v-model="scope.row.source" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="单位">
            <template slot-scope="scope">
              <el-input v-model="scope.row.company" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="用量">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.consumption" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="单价（万元）">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.unitPrice" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="总价（万元）">
            <template slot-scope="scope">
              <el-input type="number" v-model.number="scope.row.totalPrice"  placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-button type="danger" plain @click="deletes(scope.$index, liquidityList,scope.row,'liquidityList')" v-if="scope.$index != 0" :disabled="disabled">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item class="prepend-input">
          <el-input v-model="liquidityTotal" disabled :maxlength="20">
            <template slot="prepend">流动资金合计(万元)</template>
          </el-input>
        </el-form-item>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="overflow: hidden; background-color: #135694; padding: 0 20px">
        <el-form-item class="prepend-input">
          <el-input v-model="total" disabled :maxlength="20">
            <template slot="prepend">项目总投资(万元)</template>
          </el-input>
        </el-form-item>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="padding: 0 20px 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">有关投资安排计划说明</div>
        </div>
        <el-form-item prop="plan">
          <el-input type="textarea" :rows="8" v-model="saveData.plan" placeholder="请输入有关投资安排计划说明" :disabled="disabled"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'basicInfo',
  data() {
    return {
      saveData: {
        landPurchase: '',
        estimatedCost: '',
        plan: '',
      },
      infrastructureInvestmentList: [
        {
          constructionContent: '车间面积',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
        {
          constructionContent: '办公面积',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
        {
          constructionContent: '仓库面积',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
        {
          constructionContent: '研发面积',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
        {
          constructionContent: '其他面积',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
        {
          constructionContent: '合计',
          constructionArea: '',
          constructionFloors: '',
          areaCovered: '',
          estimatedCost: '',
        },
      ],
      equipmentInvestmentList: [
        {
          productionEquipment: '',
          greenFacilities: '',
        },
      ],
      liquidityList: [
        {
          name: '',
          source: '',
          company: '',
          consumption: '',
          unitPrice: '',
          totalPrice: '',
        },
      ],
      info1: {
        constructionArea: '',
        constructionFloors: '',
        areaCovered: '',
        researchArea: '',
        otherArea: '',
        estimatedCost: '',
      },
      info2: {
        productionEquipment: '',
        greenFacilities: '',
      },
      info3: {
        name: '',
        source: '',
        company: '',
        consumption: '',
        unitPrice: '',
        totalPrice: '',
      },
      saveRules: {
        landPurchase: [{ required: false, message: '请输入土地购置', trigger: 'blur' }],
        // estimatedCost: [{ required: true, message: '请输入预计费用', trigger: 'blur' }],
        plan: [
          {
            required: false,
            message: '请输入有关投资安排计划说明',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getProjectFixedInvestment(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            data.projectFixedInvestment.plan = data.plan;
            this.saveData = data.projectFixedInvestment || {};
            // this.saveData.plan = data.plan
            this.liquidityList = data.liquidityList.length > 0 ? data.liquidityList : this.liquidityList;
            this.equipmentInvestmentList = data.equipmentInvestmentList.length > 0 ? data.equipmentInvestmentList : this.equipmentInvestmentList;
            // this.infrastructureInvestmentList =
            //   data.infrastructureInvestmentList.length > 0 ? data.infrastructureInvestmentList : this.infrastructureInvestmentList;
            this.infrastructureInvestmentList.forEach((v, i) => {
              data.infrastructureInvestmentList.forEach((item) => {
                if (v.constructionContent == item.constructionContent) {
                  this.$set(this.infrastructureInvestmentList, i, item);
                }
              });
            });
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
    async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row,model})
      if(row.id){
         await this.$confirm("确认删除?","删除",{
            type:'warning'
         })
         await ({
           liquidityList:this.$api.deleteLiquidity,
           equipmentInvestmentList:this.$api.deleteProjectEquipmentInvestment
         })[model](row.id)
          // this.$api.deleteLiquidity(row.id);
         this.$message.success("删除成功")
      }
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      this.$refs['saveForm'].validate((valid,obj) => {
          if(!valid) this.$message.error(obj[ Object.keys(obj)[0]][0].message)
        flage = valid;
      });
      if (!flage) {
        return false;
      }
      // this.infrastructureInvestmentList.forEach((e) => {
      //   for (const key in this.info1) {
      //     if (!e[key] && e[key] !== 0 && e[key] !== '0') {
      //       this.$message.error({ message: `请将基建投入填写完整`, offset: 80 });
      //       flage = false;
      //       return;
      //     }
      //   }
      // });
      // this.equipmentInvestmentList.forEach((e) => {
      //   for (const key in this.info2) {
      //     if (!e[key] && e[key] !== 0 && e[key] !== '0') {
      //       this.$message.error({
      //         message: `请将设备投资填写完整`,
      //         offset: 80,
      //       });
      //       flage = false;
      //       return;
      //     }
      //   }
      // });
      // this.liquidityList.forEach((e) => {
      //   for (const key in this.info3) {
      //     if (!e[key] && e[key] !== 0 && e[key] !== '0') {
      //       this.$message.error({
      //         message: `请将流动资金安排填写完整`,
      //         offset: 80,
      //       });
      //       flage = false;
      //       return;
      //     }
      //   }
      // });
      if (!flage) return false;

      return true;
    },
  },
  watch: {
    infrastructureInvestmentList: {
      handler: function (newVal, oldVal) {
        let i = this.infrastructureInvestmentList.length - 1;
        this.infrastructureInvestmentList[i].constructionArea = this.infrastructureInvestmentList.reduce(function (a, b, c) {
          return c == i ? a : a + Number(b.constructionArea);
        }, 0);
        this.infrastructureInvestmentList[i].areaCovered = this.infrastructureInvestmentList.reduce(function (a, b, c) {
          return c == i ? a : a + Number(b.areaCovered);
        }, 0);    
        this.infrastructureInvestmentList[i].constructionFloors = this.infrastructureInvestmentList.reduce(function (a, b, c) {
          return c == i ? a : a + Number(b.constructionFloors);
        }, 0);
        this.infrastructureInvestmentList[i].estimatedCost = this.infrastructureInvestmentList.reduce(function (a, b, c) {
          return c == i ? a : a + Number(b.estimatedCost);
        }, 0);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
    // expectTotal() {
    //   return this.infrastructureInvestmentList.reduce(function(a, b) {
    //     return a + Number(b.estimatedCost);
    //   }, 0);
    // },
    equipmentTotal() {
      return this.equipmentInvestmentList.reduce(function (a, b) {
        return a + Number(b.productionEquipment) + Number(b.greenFacilities);
      }, 0);
    },
    FixedAssetsTotal() {
      return Number(this.saveData.estimatedCost) + Number(this.infrastructureInvestmentList[5].estimatedCost) + Number(this.equipmentTotal);
    },
    liquidityTotal() {
      return this.liquidityList.reduce(function (a, b) {
        return a + Number(b.totalPrice);
      }, 0);
    },
    total() {
      return Number(this.FixedAssetsTotal) + Number(this.liquidityTotal);
    },
  },
};
</script>

<style lang="scss" scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
.prepend-input {
  margin: 21px 20px 20px;
  /deep/.el-input-group__prepend {
    width: 126px;
    background: #eaf2f9;
    color: #135694;
    letter-spacing: 0.35px;
    text-align: center;
    border: none;
  }
}
</style>
