<template>
  <div>
    <el-form ref="saveForm" @submit.native.prevent label-width="85px" label-position="top" style="padding: 0 20px 10px">
      <div class="title">
        <div class="line"></div>
        <div class="text">主要设备清单</div>
      </div>
      <el-button type="primary" @click="add(table, info)" style=" margin-bottom: 20px" :disabled="disabled">新增</el-button>
      <el-table :data="table" style="width: 100%">
        <el-table-column label="设备名称">
          <template slot-scope="scope">
            <el-input :disabled="disabled" v-model="scope.row.equipmentName" placeholder="请输入" :maxlength="50"></el-input>
          </template>
        </el-table-column> 
        <el-table-column label="设备来源">
          <template slot-scope="scope">
            <el-select :disabled="disabled" v-model="scope.row.sourceEquipment" placeholder="请选择设备来源">
              <el-option label="国产" value="1"></el-option>
              <el-option label="进口" value="2"></el-option>
            </el-select>
            <!-- <el-input :disabled="disabled" v-model="scope.row.equipmentName" placeholder="请输入" :maxlength="50"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="单位">
          <template slot-scope="scope">
            <el-input :disabled="disabled" v-model="scope.row.company" placeholder="请输入" :maxlength="20"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input-plus type="integer" v-model="scope.row.number" placeholder="请输入"  :maxlength="20" :disabled="disabled" ></el-input-plus>
            <!-- <el-input type="number" :disabled="disabled" v-model.number="scope.row.number" placeholder="请输入" :maxlength="20"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="单价（元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.unitPrice" placeholder="请输入" :maxlength="20"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="金额（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.amountMoney" placeholder="请输入" :maxlength="20"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="产地">
          <template slot-scope="scope">
            <el-input :disabled="disabled" v-model="scope.row.placeOrigin" placeholder="请输入" :maxlength="100"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button type="danger" plain @click="deletes(scope.$index, table,scope.row)" v-if="scope.$index != 0" :disabled="disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item class="prepend-input">
        <el-input :disabled="disabled" v-model="totalAmount" placeholder="请输入" :maxlength="20">
          <template slot="prepend">金额合计(万元)</template>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'basicInfo',
  data() {
    return {
      table: [
        {
          equipmentName: '',
          company: '',
          number: '',
          unitPrice: '',
          amountMoney: '',
          placeOrigin: '',
        },
      ],
      info: {
        equipmentName: '',
        company: '',
        number: '',
        unitPrice: '',
        amountMoney: '',
        placeOrigin: '',
        sourceEquipment:'1'
      },
    };
  },
  computed: {
    totalAmount() {
      let result =  this.table.reduce(function (a, b) {
        return a + Number(b.amountMoney);
      }, 0);
      return (result || 0 ).toFixed(2)
    },
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getProjectEquipment(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            this.table = data.existingEquipmentList.length > 0 ? data.existingEquipmentList : this.table;
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
    async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row})
      if(row.id){
         await this.$confirm("确认删除?","删除",{ type:'warning' })
         await this.$api.deleteProjectEquipment(row.id);
         this.$message.success("删除成功")
      }
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      this.table.forEach((e) => {
          for (const key in this.info) {
          if(["placeOrigin"].includes(key)){ 
            continue; //如果是型号 和备注 则跳过本次循环
          }
          // if (!e[key] && e[key] !== 0 && e[key] !== '0') {
          //   this.$message.error({
          //     message: `请将主要设备清单填写完整`,
          //     offset: 80,
          //   });
          //   flage = true;
          //   return;
          // }
        }
      });
      if (flage) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
.prepend-input {
  margin: 21px 20px 20px;
  /deep/.el-input-group__prepend {
    width: 126px;
    background: #eaf2f9;
    color: #135694;
    letter-spacing: 0.35px;
    text-align: center;
    border: none;
  }
}
</style>
